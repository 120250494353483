import React from 'react';
import Icon from '@material-ui/core/Icon';
import './App.css';
import {Link} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Icon id={'logo'}>blur_on</Icon>
        <p>
          <code>Baker St Labs</code>
        </p>
        <p className={"address"}><Link to={"/recipes/contact"} className="App-link">contact</Link></p>
      </header>
      <footer>
          Copyright {new Date().getFullYear()}, Baker St Labs, Inc.
          {/*
          <Link to={"/recipes/privacy"}>Privacy Policy</Link> and <Link to={"/recipes/terms"}>Terms of Use</Link>
          */}
      </footer>
    </div>
  );
}

export default App;
