import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {TextareaAutosize} from "@material-ui/core";
import {adminReplaceSource} from "../../api";

const useStyles = makeStyles({

});

function EditSourceDialog(props) {
    const classes = useStyles();
    const { onClose,open,source } = props;
    const [newValue,setNewValue] = useState();

    const handleClose = () => {
        onClose()
    };

    const handleKeyUp = (e) => {
        const v = e.target.value;
        setNewValue(v);
    }

    const handleSave = (e) => {
        try {
            const newSource = JSON.parse(newValue);
            adminReplaceSource(newSource,function(){
                handleClose(newSource);
            },function(err){
                alert(err);
            });
        } catch (err) {
            alert(err);
        }
    }

    function getDto() {
        var newSource = {};
        newSource.url = source.url;
        newSource.previousSourceCreatedAt = source.createdAt;
        newSource.title = source.title;
        newSource.instructions = [];
        if (source.instructions) {
            source.instructions.forEach(function (instruction) {
                newSource.instructions.push({
                    text: instruction.text,
                    links: instruction.links,
                    section: instruction.section
                });
            });
        }
        newSource.ingredients = [];
        if (source.ingredients) {
            source.ingredients.forEach(function (ingredient) {
                newSource.ingredients.push({
                    text: ingredient.text,
                    links: ingredient.links,
                    section: ingredient.section
                });
            });
        }

        return JSON.stringify(newSource,null,2);

    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Edit the JSON
                </DialogContentText>
                <TextareaAutosize
                    autoFocus
                    rowsMax={40}
                    cols={100}
                    defaultValue={getDto()}
                    onKeyUp={handleKeyUp}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditSourceDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    source: PropTypes.object.isRequired
};

export default EditSourceDialog;