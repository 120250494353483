import React from 'react';
import './Privacy.css';

function Privacy() {
    return (
        <div className="Privacy">
            <header><h1>Privacy Policy</h1></header>
            <h3>Our Privacy Principals</h3>
            <p>
                <ul>
                    <li>We collect only data from you that we need to deliver our service to you. </li>
                    <li>You own your data. If you want to delete it, you can. If you want to download it, you can. Just contact us.</li>
                    <li>We don't give your data to others to make money "off you".</li>
                </ul>
            </p>
            <h3>Data we collect</h3>
            <p>
                We collect the following data from you when you use our service.

                <ul>
                    <li>Your phone number (which you'll use to log in and identify yourself in the service)</li>
                    <li>Your IP address</li>
                    <li>Technical information about your phone, like what OS it supports and the screen sizes available.</li>
                    <li>Any recipe URLs you upload to our service to save.</li>
                </ul>
            </p>

            <h3>How we use your data</h3>
            We use your data in the following ways:
            <p>
                <ul>
                    <li>
                        Your phone number gives you an easy way to log in and identify yourself without having to remember a password.
                        We may also occassionally contact you via SMS if we need to get a hold of you.
                    </li>
                    <li>
                        Certain information like IP addresses and other uniquely identifiable information helps us to prevent
                        misuse of our service and keep your data safe by thwarting unauthorized attempts at accessing data on our service.
                    </li>
                    <li>
                        Aggregated (anonymized) data about the device like OS version and screen size help us to develop the service, supporting
                        the widest array of devices because we know what devices our users are using.
                    </li>
                    <li>
                        Some of the data you provide us (lke the URLs of recipes you save, which recipes you pin, etc) is
                        necessary for us to store and process so we can display the information in the app to you.
                    </li>
                </ul>
            </p>

            <h3>What to do if you have a question</h3>
            Contact us:

            <p>
                Baker St Labs, Inc.<br/>
                532 Baker St.<br/>
                San Francisco, CA 94117<br/>
                <a href={"mailto:privacy@bakerstlabs.io"}>privacy@bakerstlabs.io</a>
            </p>

            <footer>
                Copyright {new Date().getFullYear()}, Baker St Labs, Inc.
            </footer>
        </div>
    );
}

export default Privacy;
