import * as React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import {useState,useCallback} from "react";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {adminGetRecentSources,adminGetSourceReviews,adminMarkReviewed} from "../../api/index"

function handleClickOpen() {
    alert('unimplemented');
}

export default function RecentSources() {
    const [sources, setSources] = useState();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [mode,setMode] = useState("QUEUE")

    function fill() {
        if (mode == "QUEUE") {
            setLoaded(false);
            adminGetSourceReviews(function (result) {
                setSources(result);
                setLoaded(true);
            }, function (error) {
                setError(error);
            })
        } else {
            setLoaded(false);
            adminGetRecentSources(function (result) {
                setSources(result);
                setLoaded(true);
            }, function(error) {
                setError(error);
            })
        }
    }
    useEffect(() => {
        fill()
    },[]);

    function switchMode() {
        if (mode == "ALL") {
            setMode("QUEUE")
            setLoaded(false);
            adminGetSourceReviews(function (result) {
                setSources(result);
                setLoaded(true);
            }, function (error) {
                setError(error);
            })
        } else {
            setMode("ALL")
            setLoaded(false);
            adminGetRecentSources(function (result) {
                setSources(result);
                setLoaded(true);
            }, function(error) {
                setError(error);
            })
        }
    }


    function handleDeleteReview(url,createdAt) {
        setLoaded(false);
        adminMarkReviewed(url,createdAt,fill,function(){
            alert("error");
        })
    }

    function deleteButton(url,createdAt) {
        if (mode=='QUEUE'){
            return <Button onClick={() =>handleDeleteReview(url,createdAt)}>Delete</Button>;
        } else {
            return ""
        }
    }


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!loaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <React.Fragment>
                <h2>{mode == 'QUEUE' ? "Source Review Queue" : "Recent Sources"}</h2>
                <Button onClick={()=>switchMode()}>Switch</Button>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Source Status</TableCell>
                            <TableCell>Report Note</TableCell>
                            <TableCell>Worker ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sources.map(row => (
                            <TableRow key={row.url}>
                                <TableCell><a href={row.url} target="_blank" rel="noopener noreferrer">{row.title}</a></TableCell>
                                <TableCell>{row.createdAt}</TableCell>
                                <TableCell>{row.sourceStatus}</TableCell>
                                <TableCell>{row.reportNote}</TableCell>
                                <TableCell><Link to={"/admin/workers/" + row.mturkWorkerId}>{row.mturkWorkerId}</Link></TableCell>
                                <TableCell>
                                    <Link to={"/admin/sources/" + encodeURIComponent(row.url)}>View</Link>
                                    {deleteButton(row.url,row.createdAt)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </React.Fragment>
        );
    }
}

