import React from 'react';
import './App.css';

function e404() {
  return (
    <div className="e404">
      Page not found.
      <footer>
        Copyright {new Date().getFullYear()}, Baker St Labs, Inc.
      </footer>
    </div>
  );
}

export default e404;
