import getCookie from "../util/cookies";

function adminSendWorkerMessage(workerId,subject,message,createdAt,success,fail) {
    fetch((process.env.NODE_ENV === 'production' ? "https://api.recipes.bakerstlabs.io/admin/workers/" : "https://dev-api.recipes.bakerstlabs.io/admin/workers/") + workerId + "/send",
        {
            method:'POST',
            headers: {
                'uid': getCookie("uid"),
                'auth': getCookie("auth")
            },
            body:JSON.stringify({workerId:workerId,subject:subject,message:message})})
        .then(res => res.json())
        .then(
            (result) => {
                success(result)
            },
            (error) => {
                fail(error)
            }
        )
}

export default adminSendWorkerMessage
