import getCookie from "../util/cookies";

function adminReplaceSource(newSource,success,fail) {
    fetch((process.env.NODE_ENV === 'production' ? "https://api.recipes.bakerstlabs.io/admin/sources/" : "https://dev-api.recipes.bakerstlabs.io/admin/sources/") + encodeURIComponent(newSource.url) + "/" + newSource.previousSourceCreatedAt,
        {
            method:'PATCH',
            headers: {
                'uid': getCookie("uid"),
                'auth': getCookie("auth")
            },
            body:JSON.stringify(newSource)}
        )
        .then(res => res.json())
        .then(
            (result) => {
                success(result)
            },
            (error) => {
                fail(error)
            }
        )
}

export default adminReplaceSource
