import React from 'react';
import './Privacy.css';

function Privacy() {
    return (
        <div className="Privacy">
            <header><h1>Terms of Use</h1></header>
            <h3>What's "Recipes by Baker St Labs"?</h3>
            <p>
               "Recipes by Baker St Labs" is an app developed to make it easy for you to bookmark the recipes you find
                from across the web. We go the extra step and use real humans to transcribe your recipes (for your personal use) into
                a standardized layout that's much easier to read. It makes cooking multiple recipes at the same time a breeze!
            </p>
            <h3>Creating an Account</h3>
            <p>
                To create an account, you simply enter your phone number followed by the PIN you receive from us via SMS.
                There is no password to remember. Creating an account acknowledges that you agree to be bound by this Terms of Service
                and the Privacy Policy.
            </p>
            <p>
                Your accounts enables you to store your recipes securely. No one else can see your recipes. If you choose to delete
                recipes, your data will be deleted off our server. If you wish to delete your account entirely, contact
                help@bakerstlabs.io and we will process your deletion.
            </p>
            <h3>Your Content</h3>
            <p>
                Recipes you add to our app are your own personal content. When you add a recipe, we have real live humans that will
                transcribe the recipe you add for you to use in our app. Because this service is for your personal use,
                there's no way to share your personal transcription outside. However, you can always view the original link
                and share that with anyone you wish.
            </p>
            <p>
                If you wish to delete your content, simply delete it in the app and your personal recipes will disappear off our servers.
            </p>
            <h3>Limits</h3>
            <p>
                While there are no limits today, because we incur the cost of employing real people to transcribe recipes,
                we reserve the right in the future add limits to how many new recipes can be posted for free. In the event we add such limits,
                we will not restrict or remove any recipes you've already uploaded.
            </p>
            <h3>Contact</h3>
            <p>
                If you have questions or need to get a hold of us: <a href="mailto:contact@bakerstlabs.io" className="App-link">contact@bakerstlabs.io</a>
            </p>
            <footer>
                Copyright {new Date().getFullYear()}, Baker St Labs, Inc.
            </footer>
        </div>
    );
}

export default Privacy;
