import React from 'react';
import './Privacy.css';

function Contact() {
    return (
        <div className="Privacy">
            <header><h1>Contact Us</h1></header>
            <h3>We're here to help:</h3>
            <table>
                <tr>
                    <td>Email:</td>
                    <td><a href="mailto:contact@bakerstlabs.io">contact@bakerstlabs.io</a></td>
                </tr>

                <tr>
                    <td>Mail:</td>
                    <td>
                        Baker St. Labs, Inc.<br/>
                        532 Baker St.<br/>
                        San Francisco, CA 94117
                    </td>
                </tr>
            </table>
            <footer>
                Copyright {new Date().getFullYear()}, Baker St Labs, Inc.
            </footer>
        </div>
    );
}

export default Contact;
