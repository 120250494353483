import getCookie from "../util/cookies";

function adminGetRecentSources(sourceUrl,success,fail) {
    fetch((process.env.NODE_ENV === 'production' ? "https://api.recipes.bakerstlabs.io/admin/sources/" : "https://dev-api.recipes.bakerstlabs.io/admin/sources/") + sourceUrl,
        {headers: {
                'uid': getCookie("uid"),
                'auth': getCookie("auth")
            }})
        .then(res => res.json())
        .then(
            (result) => {
               success(result)
            },
            (error) => {
                fail(error)
            }
        )
    }

export default adminGetRecentSources
