import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {useEffect} from "react";
import {useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {adminGetWorker,adminSendWorkerMessage} from "../../api";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";

const Worker = (props) => {
    const workerId = props.match.params.id;

    const [worker, setWorker] = useState();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const useStyles = makeStyles(theme => ({
        titleArea : {
            width:'100%',
            height:'200px'
        },
        linkSubtitle : {
            "margin-top": '-20px'
        },
        subject : {
            width:'400px'
        }
    }))


    useEffect(() => {
        adminGetWorker(workerId,function(result){
            setWorker(result);
            setLoaded(true);
        },function(error){
            setError(error);
            setLoaded(true);
        })
    },[]);

    function handleSubjectChange (event) {
        setSubject(event.target.value);
    };
    function handleMessageChange (event) {
        setMessage(event.target.value);
    };
    function sendMessage (event) {
        setButtonDisabled(true)
        adminSendWorkerMessage(workerId,subject,message,
            function(result){
                setSubject("");
                setMessage("");
            }
            ,function (){
                alert("sent");
            },function (error){
                alert("failed");
                console.log(error);
            })
    };


    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!loaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <React.Fragment>
                {/* Title Area */}
                <Paper>
                    <h1><a href={"https://requester.mturk.com/workers/" + workerId} target="_blank">{workerId}</a></h1>

                </Paper>

                <Paper>
                    <h2>Send Message</h2>
                    <FormControl className={useStyles.subject} fullWidth={true}>
                        <InputLabel htmlFor="subject">Subject</InputLabel>
                        <Input
                            id="subject"
                            value={subject}
                            onChange={handleSubjectChange}
                            aria-describedby="component-helper-text"
                            fullWidth={true}
                        />
                    </FormControl>
                    <FormControl className={useStyles.subject} fullWidth={true}>
                        <InputLabel htmlFor="message">Message</InputLabel>
                        <Input
                            id="message"
                            value={message}
                            onChange={handleMessageChange}
                            aria-describedby="component-helper-text"
                            fullWidth={true}
                            multiline={true}
                        />
                    </FormControl>
                    <Button variant="contained" className={useStyles.button} onClick={sendMessage} disabled={buttonDisabled}>
                        Send
                    </Button>

                </Paper>


                <Paper>
                    <h2>Workers</h2>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Created At</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>New Source Created At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {worker.sources ? worker.sources.map(row => (
                                <TableRow key={row.createdAt}>
                                    <TableCell>{row.createdAt}</TableCell>
                                    <TableCell><a href={row.url} target="_blank">{row.title}</a></TableCell>
                                    <TableCell>{row.newSourceCreatedAt}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )):""}
                        </TableBody>
                    </Table>
                </Paper>



            </React.Fragment>
        )
    }
}

export default Worker