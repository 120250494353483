import React from 'react'
import { render } from 'react-dom'
import App from './App';
import {HashRouter, Route,Switch} from "react-router-dom";
import e404 from "./e404";
import Admin from "./pages/admin/Admin";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";


render((
    <HashRouter>
        <Switch>
            <Route exact path='/' component={App}/>
            <Route exact path='/recipes/privacy' component={Privacy}/>
            <Route exact path='/recipes/terms' component={Terms}/>
            <Route exact path='/recipes/contact' component={Contact}/>
            <Route path='/admin' component={Admin}/>
            <Route component={e404}/>
        </Switch>
    </HashRouter>
), document.getElementById('root'));