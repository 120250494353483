import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {useEffect} from "react";
import {useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {adminGetSource, adminRequestReenrich} from "../../api";
import {Link} from "react-router-dom";
import EditSourceDialog from "./EditSourceDialog";

const Source = (props) => {
    const sourceUrl = props.match.params.url;

    const [source, setSource] = useState();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState();
    const [editDialogOpen,setEditDialogOpen] = useState(false);

    const useStyles = makeStyles(theme => ({
        titleArea : {
            width:'100%',
            height:'200px'
        },
        linkSubtitle : {
            "margin-top": '-20px'
        }
    }))


    useEffect(() => {
        adminGetSource(sourceUrl,function(result){
            setSource(result);
            setLoaded(true);
        },function(error){
            setError(error);
            setLoaded(true);
        })
    },[]);

    function reenrich() {
        adminRequestReenrich(sourceUrl,source.createdAt,function(result){
            setSource(result)
            setLoaded(true)
        },function (error) {
            setError(error)
            setLoaded(true)
        })
    }

    function handleClose(newSource) {
        setEditDialogOpen(false);
        if (newSource) {
            setSource(newSource);
        }
    }

    function handleEdit() {
        setEditDialogOpen(true);
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!loaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <React.Fragment>
                {/* Title Area */}
                <Paper>
                    <h1>{source.title}</h1>
                    <h3><a href={source.url} target="_blank">{source.url}</a></h3>
                    <Button onClick={handleEdit}>Edit</Button>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Source Status</TableCell>
                                <TableCell>{source.sourceStatus} <Button onClick={reenrich}>Request re-enrichment</Button></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Mturk Hit ID</TableCell>
                                <TableCell>{source.mturkHitId}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Mturk Worker ID</TableCell>
                                <TableCell><Link to={"/admin/workers/" + source.mturkWorkerId}>{source.mturkWorkerId}</Link></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                <Paper>
                    <h2>Ingredients</h2>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell>Links</TableCell>
                                <TableCell>Section</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {source.ingredients ? source.ingredients.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.text}</TableCell>
                                    <TableCell>{JSON.stringify(row.links)}</TableCell>
                                    <TableCell>{row.section}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )):""}
                        </TableBody>
                    </Table>
                </Paper>


                <Paper>
                    <h2>Instructions</h2>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Text</TableCell>
                                <TableCell>Links</TableCell>
                                <TableCell>Section</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {source.instructions ? source.instructions.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.text}</TableCell>
                                    <TableCell>{JSON.stringify(row.links)}</TableCell>
                                    <TableCell>{row.section}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            )):""}
                        </TableBody>
                    </Table>
                </Paper>

                <EditSourceDialog open={editDialogOpen} onClose={handleClose} source={source}  />
            </React.Fragment>
        )
    }
}

export default Source